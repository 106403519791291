<template>
    <div class="orderWrap">
        <div class="titleWrap">
            <div>
                <ul class="taskTab">
                    <li v-for="(item, index) in taskTab" :key="index" :class="nowActive == item.id ? 'on' : ''"
                        @click="changeTab(item)">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="orderData.length > 0">
            <div class="order_table">
                <div class="order_title">
                    <div class="order_title_left">
                        订单号：XXXXXXXXXXXXXXXXXXXXXXX&nbsp;&nbsp;&nbsp;&nbsp;创建时间：2022-08-05 18:23:23
                    </div>
                    <div class="order_title_right">已支付</div>
                </div>
                <div class="order_list">
                    <div class="order_list_icon">
                        <img src="../assets/images/uxb_order_icon_ke.png" alt="">
                    </div>
                    <div class="order_list_title">
                        <p>21考研考前英语阅读、写作技巧精讲班通过725省考预判822多判省通过率省考</p>
                    </div>
                    <div class="order_list_price">
                        <p>实付金额</p>
                        <strong>￥999.99</strong>
                    </div>
                    <div class="order_list_detail">
                        <p>查看详情</p>
                    </div>
                </div>

            </div>
            <div class="order_table">
                <div class="order_title">
                    <div class="order_title_left">
                        订单号：XXXXXXXXXXXXXXXXXXXXXXX&nbsp;&nbsp;&nbsp;&nbsp;创建时间：2022-08-05 18:23:23
                    </div>
                    <div class="order_title_right">已支付</div>
                </div>
                <div class="order_list">
                    <div class="order_list_icon">
                        <img src="../assets/images/uxb_order_icon_vip.png" alt="">
                    </div>
                    <div class="order_list_title">
                        <p>21考研考前英语阅读、写作技巧精讲班通过725省考预判822多判省通过率省考</p>
                    </div>
                    <div class="order_list_price">
                        <p>实付金额</p>
                        <strong>￥999.99</strong>
                    </div>
                    <div class="order_list_detail">
                        <p>查看详情</p>
                    </div>
                </div>
            </div>
            <div class="order_pages">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper" :total="400">
                </el-pagination>
            </div>
        </div>
        <div v-else class="order_noData">
            <img src="../assets/images/uxb_order_noData.png" alt="">
            <p>暂无订单</p>
        </div>
    </div>
</template>

<script>
import { getOrderList } from "../assets/api";
export default {
    data () {
        return {
            taskTab: [
                {
                    id: 2,
                    name: '全部',
                },
                {
                    id: 0,
                    name: '已支付',
                },
                {
                    id: 1,
                    name: '待支付',
                },
                {
                    id: 4,
                    name: '已取消',
                }
            ],
            nowActive: 2,
            currentPage: 4,
            orderData: []
        }
    },
    mounted () {
        // this.getOder()
    },
    methods: {
        getOder () {
            getOrderList().then(res => {
                console.log(res);
            })
        },
        changeTab (item) {
            this.nowActive = item.id;
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`);
        }
    }
}
</script>

<style lang="scss" scoped>
.orderWrap {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;

    .order_table {
        margin: 30px 0 15px 0;
    }

    .order_title {
        display: flex;
        height: 50px;
        line-height: 50px;
        background: #F5F7FA;

        .order_title_left {
            flex: 8;
            padding-left: 20px;
        }

        .order_title_right {
            flex: 2;
            padding-right: 20px;
            text-align: right;
        }
    }

    .order_list {
        display: flex;
        height: 110px;
        border: 1px solid #EFF2F5;
        align-items: center;
        justify-content: center;
        line-height: 28px;
        font-size: 16px;
        color: #333;

        .order_list_icon {
            flex: 1;
            padding-left: 20px;
        }

        .order_list_title {
            flex: 6;

            p {
                width: 60%;
            }
        }

        .order_list_price {
            flex: 2;
            text-align: center;
            border-left: 1px solid #EFF2F5;
            border-right: 1px solid #EFF2F5;
            height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .order_list_detail {
            flex: 2;
            text-align: center;
        }
    }

    .order_pages {
        display: flex;
        justify-content: end;
    }



}

.order_noData {
    text-align: center;
    margin-top: 200px;

    p {
        color: #999;
    }
}

.titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .taskTab {
        display: flex;

        li {
            margin-right: 40px;
            font-size: 16px;
            cursor: pointer;
            position: relative;

            &.on {
                color: var(--change-color);
                font-weight: bold;
            }

            &.on::after {
                clear: both;
                content: '';
                position: absolute;
                left: 50%;
                bottom: -9px;
                width: 24px;
                height: 3px;
                margin-left: -12px;
                background: var(--change-color);
                border-radius: 1.5px;
            }
        }
    }

    i {
        font-size: 16px;
    }

    .el-button.is-round {
        //padding: 10px 20px;
        width: 118px;
        height: 36px;
        padding: 10px 23px
    }

    .bluePlainBtn {
        background: #fff;
        border: 1px solid var(--change-color);
        color: var(--change-color);

        i {}

        &:hover {
            background: var(--change-color);
            color: #fff
        }
    }
}
</style>